<template>
  <div class="columns">
    <div class="column is-8">
      <div class="columns is-multiline">
        <div class="column is-12">
          <div
            class="card shadow-none has-border-radius has-background-white-ter p-5 text-left"
          >
            <div class="columns">
              <div class="column ">
                <p v-if="pago.acreditado" class="text-sm">
                  <preview tipo="persona" :id="pago.acreditado.id || pago.personas_id" v-if="pago.acreditado || pago.personas_id">
                    {{ pago.acreditado.nombre_completo }}
                  </preview>
                </p>
                <p v-if="!pago.acreditado && pago.grupo_credito" class="text-sm">
                  {{ pago.grupo_credito.nombre + ' - ' + pago.grupo_credito.sucursal.nombre  }}
                </p>
                <p class="text-2xl font-bold">
                  {{ documento.pasivo }} de la {{ documento.nombre }}
                  <preview :id="pago.parent.id" :tipo="documento.tipo">
                    #{{ idToShow(pago.parent) }}
                  </preview>
                </p>
                <p class="has-text-grey-light">
                  Folio de pago #{{ $route.params.idPago }}
                </p>
              </div>
              <div
                class="column text-right flex items-end justify-center flex-col"
              >
                <p class="has-text-primary text-2xl">
                  {{ pago.cantidad_pagada | currency }}
                </p>
                <p class="text-sm">{{ pago.moneda.nombre }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="column text-left">
          <p class="font-bold has-text-primary text-xl">Institución</p>
          <div
            class="card shadow-none has-border-radius has-background-white-ter p-5 text-left"
          >
            <p class="font-bold text-xl">
              {{ pago.empresa.nombre_comercial }}
            </p>
            <p class="has-text-grey-light mt-2">Dirección</p>
            <p>
              {{ pago.empresa.unidad_direccion }},
              {{ pago.empresa.unidad_ciudad }},
              {{ pago.empresa.unidad_estado }}
            </p>
            <p class="has-text-grey-light mt-2">Sucursal</p>
            <p>
              {{ pago.sucursal.nombre }}
            </p>
            <p class="has-text-grey-light mt-2">Teléfono</p>
            <p>
              {{ pago.sucursal.establecimiento.telefono }}
            </p>
            <p class="has-text-grey-light mt-2">Correo</p>
            <p>
              {{ pago.empresa.unidad_correo }}
            </p>
          </div>
        </div>
        <div class="column is-12" v-if="documento.tipo !== 'cuentaCP'">
          <p class="font-bold has-text-primary text-xl text-left">
            Desglose del {{ documento.pasivo }} (MXN)
          </p>
          <saldo-table :desglose="pago.desglose_pagos" />
        </div>
        <div class="column is-12" v-if="documento.tipo == 'cuentaCP'">
          <p class="font-bold has-text-primary text-xl text-left">
            Desglose del {{ documento.pasivo }} (MXN)
          </p>
          <saldo-table-cuentas :desglose="pago.desglose_pagos" :nombreCuenta="documento.nombreCuenta" />
        </div>
        <div class="column is-12" v-if="pago.desglose_saldos_credito">
          <p class="font-bold has-text-primary text-xl text-left">
            Saldo de la disposición (MXN)
          </p>
          <saldo-table :desglose="pago.desglose_saldos_credito" />
        </div>
      </div>
    </div>
    <div class="column">
      <ticket sticky :pago="pago" :documento="documento" />
    </div>
  </div>
</template>

<script>
import SaldoTable from "@/components/generals/SaldoTable";
import SaldoTableCuentas from "@/components/generals/SaldoTableCuentas";
import Ticket from "@/components/pago/Ticket";

export default {
  name: "pagoInformacion",
  props: {
    pago: Object
  },
  components: {
    SaldoTable,
    SaldoTableCuentas,
    Ticket
  },
  computed: {
    documento() {
      if (this.pago.origen.hasOwnProperty("cuentas_cobrar_pagar_id")) {
        return {
          nombre: this.pago.parent.cobro ? "Cuenta a cobrar" : "Cuenta a pagar",
          tipo: "cuentaCP",
          pasivo: this.pago.parent.cobro ? "Cobro" : "Pago",
          nombreCuenta: this.pago.parent?.tipo_de_operacion?.nombre,
        };
      } else if (this.pago.origen.hasOwnProperty("credito_core_id")) {
        return {
          nombre: this.pago.parent.pasiva
            ? "Disposición pasiva"
            : "Disposición activa",
          tipo: "credito",
          pasivo: this.pago.parent.pasiva ? "Pago" : "Cobro"
        };
      } else if (this.pago.origen.hasOwnProperty("garantia_id")) {
        return {
          nombre: "Garantía",
          tipo: "garantia",
          pasivo: this.pago.parent.pago.dispersion.pasivo ? "Pago" : "Cobro"
        };
      }
      return {
        nombre: ""
      };
    }
  }
};
</script>
